<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'CoreGroupbuyingsList',
              }"
              >團購管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>{{
              isEdit ? "編輯團購" : "我要開團"
            }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row">
        <div class="col-12 col-xl-8">
          <h4 class="mb-2 font-weight-bold">
            {{ isEdit ? "編輯團購" : "我要開團" }}
          </h4>
          <div class="mb-3"></div>

          <section class="mb-5">
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="團購名稱"
              label-for="title"
            >
              <b-form-input
                id="title"
                class="mb-2 mr-sm-2 mb-sm-0 col-12 col-xl-6"
                v-model="form.title"
                :disabled="!isEdit"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="團購說明"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                class="mb-2 mr-sm-2 mb-sm-0 col-12 col-xl-6"
                rows="5"
                v-model="form.description"
                :disabled="!isEdit"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="下單說明"
              label-for="order_message"
            >
              <b-form-input
                id="order_message"
                class="mb-2 mr-sm-2 mb-sm-0 col-12 col-xl-6"
                v-model="form.order_message"
                :disabled="!isEdit"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="下單完成說明"
              label-for="success_message"
            >
              <b-form-input
                id="success_message"
                class="mb-2 mr-sm-2 mb-sm-0 col-12 col-xl-6"
                v-model="form.success_message"
                :disabled="!isEdit"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="商品"
              label-for="prodcut"
            >
              <b-form-select
                id="prodcut"
                class="form-control form-control-sm col-12 col-xl-6"
                v-model="product_sku_id"
                :disabled="!isEdit"
              >
                <b-form-select-option :value="null" disabled>
                  請選擇商品編號
                </b-form-select-option>
                <b-form-select-option
                  v-for="sku in skuList"
                  :value="sku.id"
                  :key="sku.id"
                >
                  {{ sku.sku_name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="本群分配庫存"
              label-for="max_quantity"
            >
              <b-form-input
                id="max_quantity"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="number"
                style="width: 120px"
                v-model="form.max_quantity"
                :formatter="(value) => (value < 0 ? 0 : Math.round(value))"
                :disabled="!isEdit"
              ></b-form-input>
            </b-form-group>
            <!-- 團購類型先隱藏，由前端帶入固定資料 -->
            <!-- <b-form-group -->
            <!--   label-cols="6" -->
            <!--   label-cols-lg="2" -->
            <!--   label-size="sm" -->
            <!--   label="團購類型" -->
            <!--   label-for="type" -->
            <!-- > -->
            <!--   <b-form-input -->
            <!--     id="type" -->
            <!--     class="mb-2 mr-sm-2 mb-sm-0" -->
            <!--     type="text" -->
            <!--     style="width: 120px" -->
            <!--     v-model="form.type" -->
            <!--     :disabled="!isEdit" -->
            <!--   ></b-form-input> -->
            <!-- </b-form-group> -->
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="成團數"
              label-for="quantity"
            >
              <b-form-input
                id="quantity"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="number"
                style="width: 120px"
                v-model="form.quantity"
                :formatter="(value) => (value < 0 ? 0 : Math.round(value))"
                :state="v$.form.quantity.$error ? false : null"
                :disabled="!isEdit"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="!v$.form.quantity.less.$invalid"
              >
                需小於商品總數上限
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="成團後是否可繼續登記"
              label-for="is_force_confirmed"
            >
              <b-form-checkbox
                class="mb-2 mr-sm-2 mb-sm-0"
                style="width: 120px"
                id="is_force_confirmed"
                v-model="form.is_force_confirmed"
                :disabled="!isEdit"
              ></b-form-checkbox>
            </b-form-group>
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="每人最高購買數"
              label-for="once_max_quantity"
            >
              <b-form-input
                id="once_max_quantity"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="number"
                style="width: 120px"
                v-model="form.once_max_quantity"
                :formatter="(value) => (value < 0 ? 0 : Math.round(value))"
                :disabled="!isEdit && !isStatusPrepared"
                :state="v$.form.once_max_quantity.$error ? false : null"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="!v$.form.once_max_quantity.less.$invalid"
              >
                需小於商品總數上限
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="每人最低購買數"
              label-for="once_quantity"
            >
              <b-form-input
                id="once_quantity"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="number"
                style="width: 120px"
                v-model="form.once_quantity"
                :formatter="(value) => (value < 1 ? 1 : Math.round(value))"
                :disabled="!isEdit && !isStatusPrepared"
                :state="v$.form.once_quantity.$error ? false : null"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="!v$.form.once_quantity.less.$invalid"
              >
                需小於每人最高購買數
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="下單數量遞增數"
              label-for="once_increase_quantity"
            >
              <b-form-input
                id="once_increase_quantity"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="number"
                style="width: 120px"
                v-model="form.once_increase_quantity"
                :formatter="(value) => (value < 1 ? 1 : Math.round(value))"
                :disabled="!isEdit && !isStatusPrepared"
                :state="v$.form.once_increase_quantity.$error ? false : null"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              content-cols="12"
              content-cols-lg="6"
              label="開始時間"
            >
              <div class="d-flex">
                <div class="w-50">
                  <datepicker
                    placeholder="Select Date"
                    v-model="form.start_at.date"
                    bootstrap-styling
                    format="yyyy-MM-dd"
                    :language="zh"
                    :disabled="!isEdit && !isStatusPrepared"
                    :disabledDates="disabledDates"
                  ></datepicker>
                  <b-form-invalid-feedback
                    :state="!v$.form.start_at.date.required.$error"
                  >
                    此欄位為必填
                  </b-form-invalid-feedback>
                </div>

                <div class="ml-2 w-50">
                  <vue-timepicker
                    format="HH:mm"
                    v-model="form.start_at.time"
                    :input-class="[
                      'form-control',
                      {
                        'is-invalid':
                          v$.form.start_at.time.HH.$error ||
                          v$.form.start_at.time.mm.$error,
                      },
                    ]"
                    :disabled="!isEdit"
                  ></vue-timepicker>
                  <b-form-invalid-feedback
                    :state="
                      !v$.form.start_at.time.HH.$error ||
                      !v$.form.start_at.time.mm.$error
                    "
                  >
                    此欄位為必填
                  </b-form-invalid-feedback>
                </div>
              </div>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              content-cols="12"
              content-cols-lg="6"
              label="截止時間"
              description="*達截止時間系統將自動結團"
            >
              <div class="d-flex">
                <div class="w-50">
                  <datepicker
                    placeholder="Select Date"
                    v-model="form.end_at.date"
                    bootstrap-styling
                    format="yyyy-MM-dd"
                    :language="zh"
                    :disabled="!isEdit"
                    :input-class="{
                      'is-invalid': v$.form.start_at.$invalid,
                    }"
                    :disabledDates="disabledDates"
                  ></datepicker>
                  <b-form-invalid-feedback :state="!v$.form.end_at.date.$error">
                    此欄位為必填
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback
                    :state="!v$.form.start_at.date.beforeEndAt.$invalid"
                  >
                    截止時間必須大於開團時間
                  </b-form-invalid-feedback>
                </div>

                <div class="ml-2 w-50">
                  <vue-timepicker
                    :disabled="!isEdit"
                    :input-class="[
                      'form-control',
                      {
                        'is-invalid':
                          v$.form.start_at.time.HH.$error ||
                          v$.form.start_at.time.mm.$error ||
                          v$.form.start_at.date.beforeEndAt.$invalid,
                      },
                    ]"
                    v-model="form.end_at.time"
                  ></vue-timepicker>
                  <b-form-invalid-feedback
                    :state="
                      !v$.form.end_at.time.HH.$error ||
                      !v$.form.end_at.time.mm.$error
                    "
                  >
                    此欄位為必填
                  </b-form-invalid-feedback>
                </div>
              </div>
            </b-form-group>
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="順序"
              label-for="order"
            >
              <b-form-input
                id="order"
                class="mb-2 mr-sm-2 mb-sm-0"
                style="width: 170px"
                v-model="form.order"
                :disabled="!isEdit"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="追蹤碼"
              label-for="tracking_code"
            >
              <b-form-input
                id="tracking_code"
                class="mb-2 mr-sm-2 mb-sm-0"
                style="width: 170px"
                v-model="form.tracking_code"
                :disabled="!isEdit"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="付款期限"
              label-for="input-1"
            >
              <div>
                結單後
                <b-form-input
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="number"
                  v-model="form.payday_period"
                  style="width: 120px"
                  :disabled="!isEdit"
                  :state="
                    v$.form.payday_period && v$.form.payday_period.$error
                      ? false
                      : null
                  "
                  :formatter="(val) => (val < 1 ? 1 : val)"
                ></b-form-input>
                日後停止付款
              </div>
              <div>預計付款截止日：{{ estimatedEndDate }} 23:59</div>
            </b-form-group>
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="可否重複下單"
              label-for="can_order_again"
            >
              <b-form-checkbox
                class="mb-2 mr-sm-2 mb-sm-0"
                style="width: 120px"
                id="can_order_again"
                v-model="form.can_order_again"
                :disabled="!isEdit"
              ></b-form-checkbox>
            </b-form-group>
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="是否需要選擇數量"
              label-for="need_choose_number"
            >
              <b-form-checkbox
                class="mb-2 mr-sm-2 mb-sm-0"
                style="width: 120px"
                id="need_choose_number"
                v-model="form.need_choose_number"
                :disabled="!isEdit"
              ></b-form-checkbox>
            </b-form-group>
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="是否要選擇分店"
              label-for="need_choose_branch"
            >
              <b-form-checkbox
                class="mb-2 mr-sm-2 mb-sm-0"
                style="width: 120px"
                id="need_choose_branch"
                v-model="form.need_choose_branch"
                :disabled="!isEdit"
              ></b-form-checkbox>
            </b-form-group>
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="是否顯示商品價格"
              label-for="show_price"
            >
              <b-form-checkbox
                class="mb-2 mr-sm-2 mb-sm-0"
                style="width: 120px"
                id="show_price"
                v-model="form.show_price"
                :disabled="!isEdit"
              ></b-form-checkbox>
            </b-form-group>
          </section>
        </div>
      </div>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-button
          class="mr-4"
          variant="outline-danger"
          @click="handleCancelGroupbuy"
          >{{ isEdit ? "取消編輯" : "取消開團" }}</b-button
        >
        <b-button
          variant="success"
          v-if="isEdit"
          @click="handleSubmitGroupbuy"
          >{{ isEdit ? "確認編輯" : "確認開團" }}</b-button
        >
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import {
  addDays,
  format,
  isBefore,
  set,
  subDays,
  isWithinInterval,
} from "date-fns";
import groupbuyApi from "@/apis/groupbuy";
import getHours from "date-fns/getHours/index";
import getMinutes from "date-fns/getMinutes/index";
import { zh } from "vuejs-datepicker/dist/locale";
import { GROUPBUYING_TYPE } from "./utils";

export default {
  components: { Datepicker, VueTimepicker },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),

  data() {
    return {
      zh,
      isFetchFirstLayerBranches: false,
      isFetchSecondLayerBranches: false,
      isFetchThirdLayerBranches: false,
      isFetchGroup: false,
      isSubmmiting: false,
      selectedProduct: null,
      selectedGroup: null,
      groupOptions: null,
      disabledDates: { to: subDays(new Date(), 1) },
      productOption: null,
      productOptions: [],
      firstBranch: null,
      secondBranch: null,
      thirdBranch: null,
      firstLayerBranches: [],
      skuList: [],
      secondLayerBranches: [],
      thirdLayerBranches: [],
      product_sku_id: null,
      form: {
        type: 1, // 先寫死團購類型為門市開團（總部選品
        title: null,
        description: null,
        order_message: null,
        success_message: null,
        quantity: null,   // 成團數
        is_force_confirmed: true, // 成團後是否可繼續登記
        once_max_quantity: 1, // 每人最高購買數
        once_quantity: 1, // 每人最低購買數
        once_increase_quantity: 1, // 下單數量遞增數
        max_quantity: 0, // 商品總數上限
        current_quantity: 0, // 已登記組數
        amount: 0, // 總金額
        tracking_code: null,
        start_at: {
          date: new Date(),
          time: {
            HH: "00",
            mm: "00",
          },
        },
        end_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
        payday_period: 0,
        order: 1,
        can_order_again: false,
        need_choose_number: false,
        need_choose_branch: false,
        show_price: false,
      },
      groupbuying: null,
      boundSku: null,
      GROUPBUYING_TYPE,
    };
  },

  validations() {
    return {
      form: {
        tracking_code: {
          lengthSix: () => {
            if (!this.form.tracking_code) return true;
            return (
              this.form.tracking_code.length === 6 &&
              /^[0-9]*$/.test(this.form.tracking_code)
            );
          },
        },
        max_quantity: {
          required,
          lessThanSalableQuantity: () => {
            return this.salableQuantity
              ? this.salableQuantity >= this.form.max_quantity
              : true;
          },
        },
        quantity: {
          less: () => {
            return this.form.max_quantity
              ? Number(this.form.max_quantity) >= Number(this.form.quantity)
              : true;
          },
        },
        once_max_quantity: {
          less: () => {
            return this.form.max_quantity
              ? Number(this.form.max_quantity) >=
                  Number(this.form.once_max_quantity)
              : true;
          },
        },
        once_quantity: {
          less: () => {
            return this.form.once_max_quantity
              ? Number(this.form.once_max_quantity) >=
                  Number(this.form.once_quantity)
              : true;
          },
        },
        once_increase_quantity: { required },
        start_at: {
          date: {
            required,
            beforeEndAt: () => {
              const startDate = set(new Date(this.form.start_at.date), {
                hours: this.form.start_at.time.HH,
                minutes: this.form.start_at.time.mm,
              });

              const endDate = set(new Date(this.form.end_at.date), {
                hours: this.form.end_at.time.HH,
                minutes: this.form.end_at.time.mm,
              });

              return isBefore(startDate, endDate);
            },
          },
          time: {
            HH: { required },
            mm: { required },
          },
        },
        end_at: {
          date: { required },
          time: {
            HH: { required },
            mm: { required },
          },
        },
      },
    };
  },

  async created() {
    if ("CoreGroupbuyingsEdit" === this.$route.name) {
      this.updateFlow();
    } else if ("CoreGroupbuyingsCreate" === this.$route.name) {
      this.createFlow();
    } else {
      this.viewFlow();
    }
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      currentBranch: (state) => state.currentBranch,
    }),
    isEdit() {
      return (
        this.$route.name === "CoreGroupbuyingsEdit" ||
        this.$route.name === "CoreGroupbuyingsCreate"
      );
    },
    estimatedEndDate() {
      return format(
        addDays(this.form.end_at.date, this.form.payday_period),
        "yyyy-MM-dd"
      );
    },
    status() {
      return this.groupbuying?.status?.substring(0, 1);
    },
    isStatusPrepared() {
      return this.status === "0";
    },
    salableQuantity() {
      return this.selectedProduct?.sku_list[0]?.quantity;
    },
  },

  watch: {
    selectedProduct: function (product) {
      /*
       * 編輯團購、檢視團購，這個最高、最低、遞增數要改從 groupbuyings 抓。
       *  -> 所以isEdit 為true 時就不從product 塞quantity 進this.form
       */
      if (product && !this.isEdit) {
        this.form.once_max_quantity =
          product.sku_list[0]?.once_max_quantity ?? 1;
        this.form.once_quantity = product.sku_list[0]?.once_quantity ?? 1;
        this.form.once_increase_quantity =
          product.sku_list[0]?.once_increase_quantity ?? 1;
      }
    },
  },

  methods: {
    createFlow() {
      this.getProductSkus();
    },

    viewFlow() {
      let promise1 = this.getProductSkus();
      let promise2 = this.getGroupbuying();
      Promise.all([promise1, promise2]).then(() => {
        this.skuList.find;
        const boundSku = this.skuList.find((sku) => {
          return sku.id == this.groupbuying.sku.id;
        });

        if (!boundSku) {
          this.$swal.fire({
            title: "此團購商品已刪除, 請重新選擇",
            type: "error",
          });
          return;
        }

        this.boundSku = boundSku;
        this.product_sku_id = boundSku.id;
      });
    },

    updateFlow() {
      let promise1 = this.getProductSkus();
      let promise2 = this.getGroupbuying();
      Promise.all([promise1, promise2]).then(() => {
        this.skuList.find;
        const boundSku = this.skuList.find((sku) => {
          return sku.id == this.groupbuying.sku.id;
        });

        if (!boundSku) {
          this.$swal.fire({
            title: "此團購商品已刪除, 請重新選擇",
            type: "error",
          });
          return;
        }

        this.boundSku = boundSku;
        this.product_sku_id = boundSku.id;
      });
    },

    async getGroupbuying() {
      try {
        const { data } = await groupbuyApi.getCoreGroupbuying(
          this.$route.params.groupbuyID
        );

        const {
          title,
          description,
          order_message,
          success_message,
          type,
          max_quantity,
          once_max_quantity,
          once_quantity,
          once_increase_quantity,
          quantity,
          start_at,
          end_at,
          payday_period,
          tracking_code,
          is_force_confirmed,
          current_quantity,
          amount,
          order,
          status,
          can_order_again,
          need_choose_number,
          need_choose_branch,
          show_price,
        } = data.data;

        this.form = {
          ...this.form,
          title,
          description,
          order_message,
          success_message,
          type,
          max_quantity,
          once_max_quantity,
          once_quantity,
          once_increase_quantity,
          quantity,
          start_at: {
            date: new Date(start_at),
            time: {
              HH: String(getHours(new Date(start_at))).padStart(2, "0"),
              mm: String(getMinutes(new Date(start_at))).padStart(2, "0"),
            },
          },
          end_at: {
            date: new Date(end_at),
            time: {
              HH: String(getHours(new Date(end_at))).padStart(2, "0"),
              mm: String(getMinutes(new Date(end_at))).padStart(2, "0"),
            },
          },
          payday_period,
          tracking_code,
          is_force_confirmed,
          current_quantity,
          amount,
          order,
          status,
          can_order_again,
          need_choose_number,
          need_choose_branch,
          show_price,
        };

        this.groupbuying = data.data;
      } catch (err) {
        console.log("err", err);
      }
    },

    async getProductSkus() {
      try {
        const { data } = await groupbuyApi.getAllSkus();
        this.skuList = data.data;
      } catch (error) {
        console.error(error);
      }
    },

    async bindProductSku() {
      const param = {
        product_sku_id: this.product_sku_id,
      };
      await groupbuyApi.bindSku(this.groupbuying.id, param);
    },

    handleCancelGroupbuy() {
      this.$router.push({ name: "CoreGroupbuyingsList" });
    },

    validateProductTime() {
      if (!this.selectedProduct) return false;
      const groupbuyStartAt = new Date(
        format(
          set(new Date(this.form.start_at.date), {
            hours: Number(this.form.start_at.time.HH),
            minutes: Number(this.form.start_at.time.mm),
            seconds: 0,
          }),
          "yyyy/MM/dd HH:mm:ss" // for safari data format compatible
        )
      );
      const groupbuyEndAt = new Date(
        format(
          set(new Date(this.form.end_at.date), {
            hours: Number(this.form.end_at.time.HH),
            minutes: Number(this.form.end_at.time.mm),
            seconds: 0,
          }),
          "yyyy/MM/dd HH:mm:ss"
        )
      );

      /**
       * 產品銷售時間 start <= 開團時間 start <= 產品銷售時間 end
       * 產品銷售時間 start <= 開團時間 end <= 產品銷售時間 end
       */
      return (
        isWithinInterval(groupbuyStartAt, {
          start: new Date(this.selectedProduct.available_start_at),
          end: new Date(this.selectedProduct.available_end_at),
        }) &&
        isWithinInterval(groupbuyEndAt, {
          start: new Date(this.selectedProduct.available_start_at),
          end: new Date(this.selectedProduct.available_end_at),
        })
      );
    },

    async handleSubmitGroupbuy() {
      if (!this.product_sku_id) {
        this.$swal.fire({ title: "請先挑選商品", type: "error" });
        return;
      }

      const form = {
        ...this.form,
        start_at: format(
          set(new Date(this.form.start_at.date), {
            hours: Number(this.form.start_at.time.HH),
            minutes: Number(this.form.start_at.time.mm),
            seconds: 0,
          }),
          "yyyy-MM-dd HH:mm:ss"
        ),
        end_at: format(
          set(new Date(this.form.end_at.date), {
            hours: this.form.end_at.time.HH,
            minutes: this.form.end_at.time.mm,
            seconds: 59,
          }),
          "yyyy-MM-dd HH:mm:ss"
        ),
      };

      if (this.$route.name === "CoreGroupbuyingsEdit") {
        this.handleCoreUpdateGroupbuy(form);
      } else {
        this.handleCoreCreateGroupbuy(form);
      }
    },

    async handleCoreUpdateGroupbuy(form) {
      try {
        this.isSubmmiting = true;
        await groupbuyApi.coreUpdateGroupbuying(
          this.$route.params.groupbuyID,
          form
        );

        await this.bindProductSku();

        this.$swal
          .fire({
            type: "success",
            text: "更新成功",
          })
          .then(() => {
            this.$router.push({ name: "CoreGroupbuyingsList" });
          });
      } catch (error) {
        this.$swal({
          type: "error",
          html: error.response.data.message,
        });
      }
      this.isSubmmiting = false;
    },

    async handleCoreCreateGroupbuy(form) {
      try {
        this.isSubmmiting = true;
        const { data } = await groupbuyApi.coreCreateGroupbuy(form);
        this.groupbuying = data.data;
        await this.bindProductSku();
        this.$swal
          .fire({
            type: "success",
            text: "新增成功",
          })
          .then(() => {
            this.$router.push({ name: "CoreGroupbuyingsList" });
          });
      } catch (error) {
        console.log("");
      }
      this.isSubmmiting = false;
    },
  },
};
</script>

<style>
/* Default override (not using "append-to-body") */
.vdp-datepicker input.is-invalid,
.vue__time-picker input.is-invalid {
  border: 1px solid #dc3545 !important;
}

.vdp-datepicker input:disabled,
.vue__time-picker input:disabled {
  background-color: #e9ecef !important;
  color: #212529 !important;
}

.auto-complete {
  background-color: palegreen;
  height: 30px;
}
</style>
